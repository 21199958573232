.icp-ai-agent-suggestion-popover-content {
  --item-height: 32px;

  padding: 4px;
  max-height: calc(8 * var(--item-height));

  & .suggestion-item {
    height: var(--item-height);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: 4px;
    cursor: pointer;
  }
}
