.field-title {
  display: inline-flex;
  align-items: center;
  position: relative;

  /* duplicate class to cover ant label font-size */

  &.field-title.field-title {
    font-size: inherit;
  }
}

.field-title-required {
  content: '*';
  color: #ff4d4f;
  margin: 0 4px;
}

.field-title-tooltip {
  margin-left: 4px;
  margin-top: -1px;
}
