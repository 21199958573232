.icp-diff-view {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.icp-diff-view-title {
  flex: none;

  & > div {
    display: inline-block;
    width: 50%;
    box-sizing: border-box;
    padding: 0 12px;
    margin-bottom: 4px;

    &:first-child {
      width: calc((100% - 30px) / 2); /* monaco diff minimap is 30px */
    }

    &:last-child {
      width: calc((100% - 30px) / 2 + 30px);
    }
  }
}

.icp-diff-view-code {
  flex: 1;
}
