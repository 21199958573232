/*
  驼峰型的变量是 antd 的配置，在 IcpApp.js 里读取并写入到 html 的 style 里的,
  在这里做一层中转，这样可追溯，ide 也不会报错。
  此外，如 borderRadius 这种在 IcpApp.js 里家在的 ant 的 config 是 number，css 需要 px，所以用法是 *1px。

  .fd-workspace-panel 是用于在 FormDesigner 里实时预览 project 主题设置里的效果的变量作用域。
  useWorkspaceTheme.js 会在 FormDesigner 里注入 .fd-workspace-panel 作用域的 css vars。
*/

/* stylelint-disable */
/* noinspection ALL */
:root,
.fd-workspace-panel {
  /* Color */
  --primary-color: var(--colorPrimary);
  --primary-color-bg: var(--colorPrimaryBg);
  --primary-color-hover: var(--colorPrimaryHover); /* antd input de border hover 的是这个颜色 */
  --success-color: var(--colorSuccess);
  --warning-color: var(--colorWarning);
  --error-color: var(--colorError);
  --info-color: var(--colorInfo);

  --font-color: var(--colorText);
  --readonly-text-color: var(--colorTextTertiary);
  --border-color: var(--colorBorder);
  --border-color-light: var(--colorBorderSecondary);
  --link-color: var(--colorLink);
  --link-hover-color: var(--colorLinkHover);
  --link-active-color: var(--colorLinkActive);

  --page-background-color: #fff;
  --helper-color: #979797;

  /* Size */
  --border-radius: calc(var(--borderRadius) * 1px);
  --form-row-height: calc(var(--controlHeight) * 1px);

  /* Font */
  --font-family: var(--fontFamily);
  --emoji-font-family: "Apple Color Emoji", "Segoe UI Emoji", "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji";
  --font-size: calc(var(--fontSize) * 1px);
  --line-height: var(--lineHeight);

  /* Other */
  --box-shadow: 0 0 5px var(--border-color);
  --card-shadow: none;
  --card-backdrop-filter: unset;
  --card-background: white;
  --max-z-index: var(--zIndexPopupBase);

  /* TODO, 给 ant 和 material 分别制定默认值，就不在这里写了 */
  --card-padding: 20px;
  --card-radius: 8px;
}

/*
 ant design mobile 会加在一个 global.css，会复写 body 的字体颜色等为 --adm 的变量。这里重新覆盖统一的全局变量
 */

:root:root,
.fd-workspace-panel {
  --adm-color-primary: var(--primary-color);
  --adm-font-family: var(--font-family);
  --adm-color-text: var(--font-color);
  --adm-font-size-main: var(--font-size);
}

