@keyframes search-input-open {
  0% {
    width: 120px;
  }

  100% {
    width: 200px;
  }
}

@keyframes search-input-close {
  0% {
    width: 120px;
  }

  100% {
    width: 32px;
  }
}

@keyframes search-button-open {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes search-button-close {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.icp-search {
  --transition-time: 0.2s;

  flex: none;
  position: relative;
  text-align: right;
  height: 32px;

  & button {
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    width: 30px;
    height: 30px;
    color: #5f6368;
  }

  & .icp-search-button {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
  }

  & .icp-search-input {
    padding-left: 30px;
    padding-right: 30px;
  }

  &.icp-opening {
    animation: search-input-open ease var(--transition-time);

    & .icp-search-button {
      animation: search-button-open ease var(--transition-time);
    }
  }

  &.icp-closing {
    animation: search-input-close ease var(--transition-time);

    & .icp-search-button {
      animation: search-button-close ease var(--transition-time);
    }
  }

  &.icp-closed {
    width: 32px;
  }

  & .ant-input-prefix {
    position: absolute;
    left: 0;
    top: 0;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(0 0 0 / 40%);
  }

  & .ant-input-suffix {
    position: absolute;
    top: 0;
    right: 0;
  }
}
