.icp-edit-hover {
  display: inline-flex;
  align-items: center;
  min-width: 80px;
  height: 32px;
  line-height: 31px;
  border: 1px solid transparent;
  border-radius: var(--border-radius);
  white-space: nowrap;
  transition: padding ease 0.2s;

  &:not(.icp-disabled) {
    padding: 0 11px;
    border-bottom: 1px dashed var(--border-color);

    &:hover {
      border: 1px solid var(--border-color);
    }
  }
}
