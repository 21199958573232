.helper-text {
  line-height: 22px;
  min-height: 22px;
}

.helper-text-error {
  color: var(--error-color);
}

.helper-text-warning {
  color: var(--warning-color);
}

.helper-text-normal {
  color: var(--helper-color);
}
