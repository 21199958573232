.icp-steps {
  display: flex;
  align-items: center;

  &.horizontal {
    gap: 12px;

    & .icp-step-connector {
      height: 1px;
      min-width: 24px;
    }
  }

  &.vertical {
    gap: 8px;
    flex-direction: column;

    & .icp-step-connector {
      width: 1px;
      min-height: 24px;
      margin-left: 12px;
    }
  }

  &.icp-step-label-vertical {
    align-items: flex-start;
  }
}

.icp-step {
  display: flex;
  align-items: center;
  gap: 8px;

  &.label-vertical {
    flex: 1;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;

    & .icp-step-label {
      text-align: center;
    }

    & .icp-step-connector {
      width: calc(100% - 36px);
      position: absolute;
      left: calc(-50% + 12px);
      top: 12px;
    }
  }

  &.wait {
    color: rgb(0 0 0 / 45%);

    & .icp-step-index-icon {
      background: rgb(0 0 0 / 6%);
    }
  }

  &.process {
    & .icp-step-icon {
      color: var(--info-color);
    }

    & .icp-step-index-icon {
      background: currentcolor;

      & > span {
        color: white;
      }
    }
  }

  &.finish {
    & .icp-step-icon {
      color: var(--info-color);
    }
  }

  &.error {
    color: var(--error-color);
  }
}

.icp-step-icon {
  flex: none;
  display: flex;
  border-radius: 50%;
  font-size: 0.875em;
}

.icp-step-index-icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.icp-step-description {
  font-size: 0.875em;
}

.icp-step-connector {
  flex: auto;

  &.wait {
    background: rgb(5 5 5 / 6%);
  }

  &.process,
  &.finish {
    background: var(--info-color);
  }

  &.error {
    background: var(--error-color);
  }
}
