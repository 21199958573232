.pdf-chat-ai {
  height: 100%;
  background-color: #f0f5ff;

  & .ai-agent {
    max-width: 1440px;

    & .ai-agent-input-actions {
      margin-right: 8px;
    }
  }
}

#root.pdf-chat-embedded {
  height: 100vh;

  & .template-wrapper {
    padding: 0;
    background-color: #f0f5ff;

    & .template-content {
      padding: 0;
      overflow-y: hidden;
    }
  }
}

@media screen and (max-width: 512px) {
  #root.pdf-chat-embedded {
    & .template-wrapper {
      margin-left: 0;

      & .template-sidebar {
        display: none;
      }
    }
  }
}
