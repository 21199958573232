.table-text-editor {
  width: 100%;
}

.table-select-editor,
.table-date-editor,
.table-time-editor,
.table-acl-editor,
.table-number-editor {
  width: 100%;

  & > div,
  &.number-picker-element .ant-input-number,
  &.date-picker-element .ant-picker,
  &.time-picker-element .ant-picker {
    width: 100%;
  }
}

.table-upload-cell,
.table-upload-editor {
  line-height: 0;

  & .ant-upload-list {
    display: inline-block;
  }

  & .ant-upload-wrapper.ant-upload-picture-card-wrapper {
    width: auto;

    & .ant-upload-list-picture-card {
      & .ant-upload {
        width: calc(var(--ag-row-height) - 8px);
        height: calc(var(--ag-row-height) - 8px);
        line-height: calc(var(--ag-row-height) - 8px);
        margin: 0;
        border-radius: 2px;

        & svg + div {
          display: none;
        }
      }

      & .ant-upload-list-item-container {
        width: calc(var(--ag-row-height) - 8px);
        height: calc(var(--ag-row-height) - 8px);
        margin-right: 4px;
        margin-bottom: 0;
      }
    }

    & .upload-item-render {
      padding: 0;
      border-radius: 2px;
    }
  }

  & .ant-progress {
    margin-left: 4px;
  }
}

.ag-cell-inline-editing {
  /* TODO, 临时方案让 table 在适配 material 之前能用 */
  /* stylelint-disable-next-line */
  & .MuiInputBase-input {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.table-textarea-editor {
  background: white;
}
