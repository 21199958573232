.flow-form-renderer {
  & > .approval-buttons {
    margin: 12px 0 0;
    text-align: right;
  }
}

.flow-form-renderer.buttons-top {
  display: flex;
  flex-direction: column-reverse;

  & .approval-content {
    flex: 1;
  }
}

.ant-modal.approval-dialog {
  & .ant-modal-content {
    padding-bottom: 64px;
  }

  & .approval-buttons {
    position: absolute;
    right: 24px;
    bottom: 20px;
    width: calc(100% - 24px);
    background: inherit;
  }
}
