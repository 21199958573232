.icp-code-editor {
  box-sizing: border-box;
  position: relative;
  background: white;
  height: 100%;
  border-radius: var(--border-radius);
  overflow: hidden; /* for the purpose of set resize style */

  &.bordered {
    border: 1px solid var(--border-color);
    transition: border 200ms linear, box-shadow 200ms linear;

    &:hover,
    &:focus-within {
      border-color: #40a9ff;
    }

    &:focus-within {
      border-right-width: 1px;
      box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
      outline: 0;
    }
  }
}

.monaco-editor {
  & .scrollbar.vertical {
    width: 12px !important;

    & .slider {
      width: 6px !important;
      border-radius: 10px;
      left: 3px !important;
    }
  }

  & .scrollbar.horizontal {
    height: 12px !important;

    & .slider {
      height: 6px !important;
      border-radius: 10px;
      top: 3px !important;
    }
  }
}
