/* 两次 root 提高优先级 */
:root:root {
  --dhx-gantt-font-family: var(--font-family);
  --dhx-gantt-font-size: var(--font-size);
  /* --dhx-gantt-task-background: var(--primary-color); */
  --dhx-gantt-base-colors-select: rgb(24 144 255 / 16%);
  --dhx-gantt-base-colors-border: #e8e8e8;
  --dhx-gantt-container-color: var(--font-color);
  --dhx-gantt-base-colors-hover-color: rgb(0 0 0 / 4%);
  --dhx-gantt-base-colors-icons: #5f6368;
  --dhx-gantt-link-background: #a1a4a6;
  /* --dhx-gantt-link-background: rgb(223 175 73); */

  --gantt-header-foreground-color: rgb(51 51 51 / 70%);
}

.gantt-element {
  display: flex;
  flex-direction: column;
  height: 300px;

  & .gantt_container {
    border: 0;
    -webkit-font-smoothing: initial;
  }

  & .gantt_task_cell,
  & .gantt_task .gantt_task_scale .gantt_scale_cell {
    border-color: #f2f2f2;
  }

  & .gantt_task_row {
    border: 0;
  }

  & .gantt_grid_scale .gantt_grid_head_cell {
    text-align: left;
    padding: 0 8px;
    color: var(--gantt-header-foreground-color);
  }

  & .gantt_task .gantt_task_scale .gantt_scale_cell {
    color: rgb(51 51 51 / 50%);
    border: 0;
    overflow: visible;
  }

  & .gantt_scale_line {
    font-size: 12px;

    &.hour-scale-row {
      & .gantt_scale_cell {
        /* hour 显示的是 12:00，对其线会比较符合直觉 */
        transform: translate(-50%, 0);
      }
    }
  }

  & .gantt_task .gantt_scale_line:first-child {
    & .gantt_scale_cell {
      font-size: 14px;
      color: var(--font-color);
      padding-left: 8px;
      /* text-align: left; */
    }
  }

  & .gantt_grid_scale {
    font-size: var(--font-size);
  }

  & .gantt_task_cell.no-work-time-cell {
    background: rgb(0 0 0 / 4%);
  }

  & .gantt_tree_icon {
    &.gantt_folder_open,
    &.gantt_folder_closed,
    &.gantt_file {
      visibility: hidden;
      width: 4px;
    }

    &.gantt_blank {
      width: 24px;
    }

    &.gantt_close,
    &.gantt_open {
      background: none;
      position: relative;
      width: 24px;
      height: 24px;
      border-radius: 6px;

      &:hover {
        background: rgb(0 0 0 / 4%);
      }

      &:active {
        background: rgb(0 0 0 / 10%);
      }

      &::before,
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        border-radius: 2px;
        background: var(--dhx-gantt-base-colors-icons);
        left: calc(-50% + 3px);
        top: calc(50% - 2px);
        transform-origin: right center;
      }

      &::before {
        transform: scale(0.3) translate(0, 1px) rotate(45deg);
      }

      &::after {
        transform: scale(0.3) translate(0, -1px) rotate(-45deg);
      }
    }

    &.gantt_close {
      transform: rotate(90deg);
    }
  }

  & .gantt_cell {
    padding-left: 8px;
    padding-right: 8px;
    font-size: var(--font-size);
  }

  & .gantt_cell_tree {
    align-items: center;
  }

  & .gantt_grid_head_add.gantt_grid_head_cell {
    margin-left: 8px;
  }

  & .gantt_add,
  & .gantt_grid_head_add.gantt_grid_head_cell {
    display: inline-block;
    vertical-align: middle;
    background: none;
    position: relative;
    width: 24px !important;
    height: 24px;
    padding: 0;
    border-radius: 6px;

    &:hover {
      background: rgb(0 0 0 / 4%);
    }

    &:active {
      background: rgb(0 0 0 / 10%);
    }

    &::before,
    &::after {
      content: '';
      position: absolute;
      width: 16px;
      height: 2px;
      border-radius: 2px;
      background: var(--dhx-gantt-base-colors-icons);
      left: 50%;
      top: 50%;
    }

    &::after {
      transform: translate(-50%, -50%) rotate(90deg) scale(0.7);
    }

    &::before {
      transform: translate(-50%, -50%) scale(0.7);
    }
  }

  & .gantt_task_link div.gantt_link_arrow {
    line-height: 1;
  }

  & .gantt_task_line.gantt_selected:not(:hover) {
    & .gantt_link_point,
    & .gantt_task_progress_drag {
      display: none;
    }
  }

  & .gantt_task_content {
    text-align: left;
    padding-left: 6px;
    padding-right: 6px;
    /* color: var(--font-color);
    overflow: visible; */
  }
}

.icp-gantt-today-line {
  opacity: 0.8;
}

.gantt-action-cell {
  & button {
    color: var(--dhx-gantt-base-colors-icons);
  }
}

.gantt-action-popover {
  padding: 4px;

  & > button {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    padding: 4px 10px;
    border: 0;
    border-radius: 4px;
    height: 28px;
  }
}

/*
.gantt-action-cell {
  height: 100%;
  opacity: 0;
}

.gantt_row:hover .gantt-action-cell {
  opacity: 1;
}
*/
