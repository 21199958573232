*,
::before,
::after {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  font-family: var(--font-family);
  font-size: var(--font-size);
  line-height: var(--line-height);
  color: var(--font-color);
  background: var(--page-background-color);
}

iframe {
  border: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1 {
  font-size: 2rem;
  font-weight: 500;
}

h2 {
  font-size: 1.5rem;
  font-weight: 500;
}

h3 {
  font-size: 1.17rem;
  font-weight: 500;
}

h4 {
  font-size: 1rem;
  font-weight: 500;
}

h5 {
  font-size: 0.83rem;
  font-weight: 500;
}

h6 {
  font-size: 0.67rem;
  font-weight: 500;
}

a {
  /*
   ant mobile 会家在一个 global.css 来覆盖 a 默认 color 是  --adm-color-primary，也就是永远等于主色调。
   这里用 is 加大一下优先级。is 语法低版浏览器不支持，会退化成 --adm-color-primary，也影响不大。
   */

  &:is(a) {
    color: var(--link-color);
  }

  &:hover {
    color: var(--link-hover-color);
  }

  &:active {
    color: var(--link-active-color);
  }

  text-decoration: none;
  cursor: pointer;
}

button {
  font-family: var(--font-family);
  font-size: var(--font-size);
  color: var(--font-color);
}

[tabindex='-1'] {
  outline: none;
}

.app-loading-wrapper {
  position: fixed;
  inset: 0;
}

.icp-connector-list {
  box-sizing: border-box;
  height: 100%;
  padding: 20px;
  border-radius: 8px;
  background: white;
}

.icp-dialog-form {
  & .input-element {
    width: 100%;
  }
}

.react-flow__attribution {
  display: none !important;
  pointer-events: none;
}
