.demo-chat {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 16px;
}

.demo-chat-search {
  flex: none;
  display: flex;
  align-items: flex-start;
  position: relative;
  text-align: right;
  width: 690px;
  max-width: calc(100% - 40px);
  min-height: 44px;
  border-radius: 24px;
  margin: 0 auto 40px;
  padding: 0 8px 0 20px;
  border-color: rgb(223 225 229 / 0%);
  box-shadow: 0 2px 8px 1px rgb(64 60 67 / 24%);
  background: white;

  & button {
    vertical-align: middle;
    margin: 0;
    padding: 0;
    border: 0;
    background: transparent;
    outline: none;
    width: 38px;
    height: 44px;
    color: #5f6368;
  }
}

.demo-chat-search-input {
  flex: 1;
  padding: 0;
  margin: 11px 0;
  border: 0;
  box-shadow: none;
  outline: none;
  resize: none;
  font-family: inherit;
  font-size: 16px;
  line-height: 22px;
  white-space: nowrap;
  overflow: hidden;

  &:focus {
    white-space: pre-wrap;
  }
}

.demo-chat-search-split {
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  height: calc(44px * 0.65);
  margin: 0 4px;
  background: #dadce0;
}

.demo-chat-form {
  flex: auto;
}
