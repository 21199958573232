.icp-table-filter-panel {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 44px;
  gap: 6px;
  padding: 6px 0;
  font-size: 14px;
  color: rgb(51 51 51 / 70%);
}

.icp-table-filter-item {
  gap: 4px;
  box-sizing: border-box;
  height: 28px;
  padding: 0 11px;
  border-radius: 16px;
  background: white;
  border: 1px solid #e8e8e8;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  &.has-value {
    background: transparent;
    position: relative;
    overflow: hidden;
    z-index: 0;
    border-color: var(--primary-color);
    color: var(--primary-color);

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: -1;
      background: var(--primary-color);
      opacity: 0.02;
    }
  }

  & .icp-filter-type {
    font-size: 13px;
  }

  & .icp-filter-value {
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.icp-table-filter-item-text {
  max-width: 200px;
}

.icp-table-filter-add-button {
  height: 28px;
  border-radius: 16px;
  color: currentcolor;

  & svg {
    color: currentcolor;
  }
}

.icp-table-filter-delete-button {
  width: 20px;
  min-width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 50%;
}

.icp-table-filter-item-detail {
  display: flex;
  flex-direction: column;
  min-width: 260px;
  max-height: 480px;

  & .ant-picker,
  & .ant-input-number input,
  & input {
    padding-top: 2.5px;
    padding-bottom: 2.5px;
  }

  & .ant-input-number,
  & .ant-picker,
  & input {
    min-width: 120px;
  }
}

.icp-table-filter-item-condition {
  flex: none;
  justify-content: space-between;
  gap: 4px;
  margin: 6px 6px 0;

  & button {
    height: 24px;
    min-width: 24px;
    padding: 0 4px;
  }
}

.icp-table-filter-item-value {
  justify-content: flex-start;
  gap: 4px;
  margin: 6px;

  & > * {
    flex: auto;
  }
}

.icp-filter-set-values {
  display: flex;
  flex-direction: column;
  gap: 2px;
  margin-left: 0;
  margin-right: 0;
  min-height: 0;

  & > .icp-search {
    margin: 0 6px;
  }

  & > .icp-table-menu {
    overflow: auto;
    padding-bottom: 0;
  }
}
