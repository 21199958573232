.icp-emoji {
  font-family: var(--emoji-font-family);
  line-height: 1em;
}

.icp-icon {
  display: inline-block;
  vertical-align: text-bottom;
  overflow: visible;
}
