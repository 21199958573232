.icp-acl {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;

  &.narrow-mode {
    gap: 4px;
  }

  & > .select-element.select-element {
    flex: 1;
    width: 100%;
    min-width: 0;
    margin-bottom: 0;

    /* ant 会默认让多选 search 的 input 动态根据文本显示宽度，但是这样会导致只选了一个的时候当宽度不够也会换行。
     * 这里让 search 永远跟在最后一个选中项的后面，不会因为 search 导致换行破坏布局
     */
    & .ant-select-selection-overflow-item-suffix {
      flex-grow: 1;
      width: 0;

      & > .ant-select-selection-search {
        width: 100% !important;
      }
    }
  }
}

.icp-acl-input.icp-acl-input.icp-acl-input {
  flex: 1;
}

.acl-table {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.icp-acl-dialog {
  &.icp-acl-dialog.multiple {
    & .ant-modal-footer {
      margin-top: 0;
    }
  }

  & .ant-modal-content {
    height: 800px;
  }
}

.icp-table-transfer {
  height: 100%;
  display: flex;
  gap: 8px;
}

.icp-transfer-list {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.icp-transfer-border {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.icp-transfer-operator {
  flex: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.icp-transfer-count {
  height: 24px;
  font-weight: 500;
  margin-top: 12px;
}
