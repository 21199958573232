.readonly-text {
  font-size: 14px;
  display: flex;
  box-sizing: border-box;
  min-height: var(--form-row-height);
  padding: 5px 12px 5px 0; /* andD input padding, remove left padding */
  color: var(--readonly-text-color);
  word-break: break-all;

  & .acl-readonly-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 16px;
    max-width: 500px;
  }
}

.input-element {
  display: flex;
  align-items: flex-start;
  width: 100%;

  &.i18n-value-allowed {
    position: relative;

    & .ant-input-group-wrapper ~ .ant-input-group-wrapper {
      margin-top: 8px;
    }

    & > div ~ div {
      margin-top: 12px;
    }

    & > div:last-child {
      position: relative;
    }
  }

  & > div:last-child {
    flex: 1;
    width: 100%;
    min-width: 0;
  }

  /* set special component height  */

  & .row-element {
    min-height: var(--form-row-height);
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  &:hover {
    & .i18n-input-layout-switch-button {
      opacity: 1;
    }
  }
}

button.i18n-input-layout-switch-button {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 4px;
  transform: translate(calc(-100% - 0px), 0);
  transition-property: opacity, background;
  transition-delay: 200ms, 0s;
}

.textarea-element .readonly-text {
  white-space: pre-wrap;
}

.textarea-element {
  & .element-value-i18n-select {
    width: 100%;
    margin-bottom: -1px;

    & .ant-select-selector {
      border-color: var(--border-color) !important;
      box-shadow: none !important;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background: rgb(0 0 0 / 2%);
    }
  }

  &.i18n-value-allowed textarea {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.textarea-element-addon {
  box-sizing: border-box;
  height: 32px;
  line-height: 30px;
  padding: 0 11px;
  margin-bottom: -1px;
  background: rgb(0 0 0 / 2%);
  border: 1px solid var(--border-color);
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.textarea-element-i18n-single ~ .textarea-element-i18n-single {
  margin-top: 8px;
}

.date-picker-element:not(.show-time),
.time-picker-element {
  & .ant-picker {
    width: 40%;
  }
}

.date-picker-element-time-zone-info {
  text-align: center;
  color: #333333b3;
}

.number-picker-element {
  & .ant-input-number {
    width: 40%;
  }
}

.select-element {
  & .ant-select {
    width: 100%;
  }
}

.cascader-element {
  & .ant-cascader {
    width: 100%;
  }
}

.tree-select-element {
  & .ant-tree-select {
    width: 100%;
  }
}

.tree-select-material {
  &:hover {
    & .clear-btn {
      opacity: 1;
    }
  }
}

.list-element {
  display: flex;
  gap: 24px;
  overflow: auto;

  &.direction-row {
    flex-direction: row;
  }

  &.direction-column {
    flex-direction: column;
  }

  &.wrap {
    flex-wrap: wrap;
  }

  & .list-item {
    position: relative;
  }
}

.status-element {
  display: inline-flex;
  align-items: center;

  & i {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.image-element {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  & img {
    max-width: 100%;
    max-height: 100%;
  }
}

.rich-text-element {
  align-items: flex-start;

  /* z-index: 100; 为什么是100？没看懂先注释掉 */

  & .form-rich-text-editor {
    width: 100%;
    background: white; /* In some scale level, richText toolbar and richText body has 1px transparent gap */

    &.w-e-full-screen-container {
      z-index: var(--max-z-index);
    }
  }
}

.icon-upload-element .icon-upload-wrapper {
  display: block;
  position: relative;
  border-radius: var(--border-radius);
  overflow: hidden;

  &:not(.view-only) {
    cursor: pointer;
  }

  & .icon-upload-indicator {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    color: white;
    opacity: 0;
    background: rgb(0 0 0 / 40%);
    transition: opacity linear 0.1s;
    pointer-events: none;
  }

  & .icon-upload-default-icon {
    color: gray;
    background: lightgray;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &:hover {
    & .icon-upload-indicator {
      opacity: 1;
    }
  }

  & img {
    width: 100%;
    height: 100%;
  }
}

.code-editor-element {
  & .code-editor-element-inner {
    height: 300px;
  }
}

.ant-upload-select {
  position: relative;

  & .self-defined-upload-input {
    opacity: 0;
    position: absolute;
    width: 100%;
    cursor: pointer;
    height: 100%;
    left: 0;
    top: 0;
  }
}

.upload-item-render {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 100%;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;

  & img {
    max-width: 100%;
    max-height: 100%;
  }

  &:not(.uploading) {
    &::after {
      content: '';
      position: absolute;
      inset: 0;
      opacity: 0;
      background: rgb(0 0 0 / 50%);
      transition: opacity linear 0.3s;
      pointer-events: none;
    }
  }

  & .eye-icon {
    position: absolute;
    left: calc(50% - 8px);
    top: calc(50% - 8px);
    opacity: 0;
    color: white;
    transition: opacity linear 0.3s;
    z-index: 1;
    pointer-events: none;
  }

  &:hover {
    & .eye-icon {
      opacity: 1;
    }

    &::after {
      opacity: 1;
    }
  }

  & .upload-item-remove-icon {
    position: absolute;
    display: flex;
    right: -6px;
    top: -6px;
    background-color: #fff;
    border-radius: 50%;
    z-index: 5;
  }

  & .uploading-progress {
    position: absolute;
    left: 0;
    bottom: 0;
    margin: 0;
    padding: 0 8px;
    line-height: 10px;
  }
}

.button-element {
  vertical-align: middle;
}

.icon-element {
  flex: none;
  display: inline-block;
  vertical-align: middle;
}

.qr-code-element {
  vertical-align: middle;
}

div.table-element {
  height: 300px;
}

.editable-table-element {
  /* TODO, editable table 里 table 少了 ag-cell-wrapper，需要研究为什么 */
  & .table-action-cell,
  & .table-enum-cell {
    height: 100%;
  }

  & .add-new-row-button:not(.ant-btn-disabled) {
    margin-top: 8px;
    border: 1px dashed var(--primary-color);
    background: transparent;
    color: var(--primary-color);
    text-shadow: none;
    box-shadow: none;

    &:hover {
      border: 1px dashed var(--primary-color-hover);
      background: var(--primary-color-bg);
      color: var(--primary-color-hover);
    }
  }
}

.editable-table-col-header-required {
  &::after {
    content: '*';
    position: absolute;
    left: 1px;
    color: #f00;
  }
}

.gantt-element,
.e-chart-element {
  position: relative;

  & .icp-loading {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.success-element {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 48px 32px;

  & > svg {
    color: #52c41a;
    margin-bottom: 24px;
  }

  & > div:nth-child(2) {
    margin: 8px;
    font-size: 24px;
  }

  & > div:nth-child(3) {
    color: #999;
  }

  & > button {
    width: 120px;
    margin-top: 48px;
  }

  &.is-mobile {
    height: 100vh;
    padding: 0;

    & > button {
      width: 180px;
      height: 48px;
      font-size: 18px;
    }
  }
}

.nav-tabs-element {
  &.ant-tabs {
    & .ant-tabs-nav {
      margin-bottom: 0;
    }
  }
}

.ocr-element {
  position: relative;

  & .ant-input {
    padding-right: 32px;
  }
}

.ocr-element-camera-btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 32px;
  overflow: hidden;

  & input {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.ocr-element-loading-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0 0 0 / 10%);
  z-index: var(--max-z-index);
}

.carousel-element {
  position: relative;

  & .arrow {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    z-index: 1;
    padding: 0 10px;
    color: var(--reset-color, var(--primary-color));

    &.left {
      left: 20px;
    }

    &.right {
      right: 20px;
    }
  }
}

.collapse-element {
  &.item-split {
    & .ant-collapse {
      background: transparent;
    }

    & .ant-collapse-item {
      background: white;
      border-radius: inherit;
      border-bottom: 0;

      &:not(:first-child) {
        margin-top: 12px;
      }
    }
  }
}

.page-header-element {
  display: flex;
  align-items: center;
  margin-bottom: 40px;

  & .page-header-left {
    flex: 1;

    &.style4 {
      display: flex;
      align-items: flex-start;
      gap: 8px;

      & .page-title {
        margin-bottom: 4px;
      }

      & .page-sub-title {
        font-size: 0.875rem;
        color: rgb(145 158 171);
      }
    }
  }

  & .page-header-actions {
    flex: none;
    display: inline-flex;
    align-items: center;
    gap: 12px;
  }

  & .page-title {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}

.progress-element {
  --status-color: var(--primary-color);

  &.warning {
    --status-color: var(--warning-color);
  }

  &.success {
    --status-color: var(--success-color);
  }

  &.error {
    --status-color: var(--error-color);
  }
}

.org-tree-element {
  display: flex;
  gap: 20px;
  align-items: stretch;

  & > .org-tree-container-left {
    flex: 1;
    overflow-y: auto;

    & > * {
      height: 100%;
    }
  }

  & > .org-tree-container-right {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    & > *:nth-child(1) {
      /*       flex: 0;
    }

    & > *:nth-child(2) { */
      flex: 1;
    }
  }
}

.swiper-element {
  & .mock-item {
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    font-size: 36px;
    font-weight: bold;
    color: #bbb;
  }
}
