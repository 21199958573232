.icp-icon-picker {
  padding: 0;
  background: none;
  border: 0;
  outline: none;
  border-radius: var(--border-radius);
  cursor: pointer;
}

.icp-icon-picker-empty {
  width: 100%;
  height: 100%;
  background: rgb(0 0 0 / 6%);
  border-radius: var(--border-radius);
}

.icp-icon-picker-list {
  display: inline-flex;
  flex-direction: column;
  /* width: 416px; */
  height: 400px;
}

.icp-icon-picker-list-header {
  display: flex;
  justify-content: space-between;
  gap: 48px;
  padding: 6px 8px 5px;
  border-bottom: 1px solid rgb(0 0 0 / 8%);

  & > button {
    height: 28px;
    border-radius: 4px;
  }
}

.icp-icon-picker-list-content {
  flex: 1;
  overflow-y: auto;
}

.icp-icon-section-icons {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(32px, 1fr));
  gap: 8px;
  position: relative;
  margin: 0 8px 8px;
}

.icp-icon-picker-list-icon {
  width: 32px;
  height: 32px;
  padding: 0;
  border-radius: 4px;
  cursor: pointer;
  background: transparent;
  border: none;
  box-shadow: none;
}

.icp-icon-section-name {
  padding: 6px 10px;
  position: sticky;
  top: 0;
  background: white;
  z-index: 1;
}

.icp-icon-picker-list-search {
  margin: 12px 12px 6px;
}
