.sale-consultation-ai {
  & .chat .chat-message .chat-message-content .analysis-message .chat-message-content-box {
    padding: 10px 15px 15px;
  }

  & .analysis-message {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & .analysis-message-title {
      color: #3f495d;
    }

    & .analysis-message-tags {
      display: flex;
      flex-direction: row;
      gap: 8px;
      margin-top: 10px;

      & .analysis-message-tag {
        background: #e6f7ff;
        color: #1890ff;
        font-size: 12px;
        height: 22px;
        border-radius: 11px;
        border: none;
        padding: 1px 8px;
      }
    }

    & .analysis-message-content {
      margin-top: 10px;
      background: #f0f5ff;
      color: #7b8495;
      border-radius: 10px;
      padding: 16px 20px;
      white-space: pre-line;
    }

    & .analysis-message-footer {
      margin-top: 10px;
      display: flex;
      flex-direction: row;
      gap: 16px;
      align-items: center;
    }
  }
}
