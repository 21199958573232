.df-data-filter-group {
  --indent-width: 12px;

  position: relative;
  margin-left: calc(var(--indent-width));
  transition: background-color 150ms linear;
  border-radius: 8px;

  &:hover {
    background-color: rgb(0 0 0 / 2%);
  }
}

.fd-setting-item {
  display: flex;
  flex-direction: row;

  .fd-setting-label {
    flex: 0 0 33.333%;
  }
}

.df-data-filter-group-line {
  --border-color: #dcdedf;
  --assume-row-height: 32px;

  position: absolute;
  top: calc(var(--assume-row-height) * 0.5);
  left: calc(-1 * var(--indent-width));
  bottom: calc(var(--assume-row-height) * 0.5);
  width: calc(var(--indent-width) * 2);
  background-color: transparent;
  border-top: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
  border-radius: 4px 0 0 4px;
}

.fd-data-filter-row {
  display: flex;
  align-items: center;
  gap: 8px;

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  & .ant-input {
    flex-basis: 200px;
  }

  & .fd-data-filter-btn.fd-data-filter-btn {
    background: #e6f4ff;

    &:hover {
      background: #bae0ff;
    }
  }
}
