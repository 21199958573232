.permissions-element {
  &.permissions-element {
    width: 100%;
    height: 80vh;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  & .perm-category-section {
    &:not(:last-child) {
      margin-bottom: 24px;
    }

    & .section-title {
      font-size: 16px;
      padding: 2px 0 2px 20px;
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      background-color: rgb(245 248 250);
    }

    & .section-title > *:not(:last-child) {
      margin-right: 20px;
      font-weight: 500;
    }

    & .form-group {
      padding-left: 20px;
    }
  }
}
