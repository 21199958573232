.ai-agent {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;

  --ai-agent-min-width: 346px;
}

.ai-agent-body {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.ai-agent-body-loading {
  display: block;
  margin-top: 20px;
}

.ai-agent .ai-agent-input-area {
  border-top: 1px solid #fff;
  background: #f0f5ff;
  padding: 16px 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: var(--ai-agent-min-width);

  & .ai-agent-files-container {
    max-height: 150px;
    overflow-y: auto;

    & .ai-agent-file-list .ai-agent-file {
      padding-left: 12px;
      border-radius: 4px;

      &:hover {
        background: #ffffff80;
      }
    }
  }

  & .ai-agent-input-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    background: #fff;

    &.input-disabled {
      cursor: not-allowed;
    }
  }

  & .ai-agent-input-toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
    margin-bottom: 8px;

    & .ai-agent-input-select {
      margin-left: 10px;
      min-width: 100px;
      font-size: 12px;
      width: 100%;

      & .ant-select-selector {
        border-radius: 16px;
        border: 1px solid #e3eaf8;
      }
    }

    & .ai-agent-input-button {
      margin-right: 10px;

      &:hover {
        cursor: pointer;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }
  }

  & .ai-agent-input-actions {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 8px;
    width: 100%;

    --hover-background: #f3f7ff;

    & button {
      margin: 0;
      padding: 0;
      border: 0;
      background: none;
      border-radius: 6px;
      width: 30px;
      height: 30px;
      color: #5f6368;

      &:hover {
        cursor: pointer;
        background: var(--hover-background);
      }
    }

    & .ant-tooltip-disabled-compatible-wrapper:hover {
      background: var(--hover-background);
      border-radius: 6px;
      width: 30px;
      height: 30px;
    }
  }
}

.ai-agent-file-list .ai-agent-file {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;

  & .ai-agent-file-info {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
  }

  & .ai-agent-file-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      width: 20px;
      height: 20px;
    }
  }

  & .ai-agent-file-remove {
    background: none;
    cursor: pointer;

    & svg {
      color: #333;
    }
  }
}

.ai-agent .ai-agent-conversation {
  padding: 10px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  flex-grow: 1;
  overflow-y: auto;
  height: 100%;
  min-width: var(--ai-agent-min-width);

  & .ai-agent-message-avatar {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 44px;
    height: 44px;
    box-sizing: border-box;
    flex-shrink: 0;

    & .ai-agent-message-avatar-me {
      background: white;
      border: 1px solid #d6e0f3;

      & svg {
        color: #aebfe0;
      }
    }

    & .ai-agent-message-avatar-ai {
      background: none;
      border: 1px solid transparent;

      & svg {
        color: white;
      }
    }
  }

  & .ai-agent-message {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-left: 0;
    margin-right: 20px;
    gap: 8px;

    &.reverse {
      flex-direction: row-reverse;
      margin-left: 20px;
      margin-right: 0;
    }
  }

  & .ai-agent-message-content:has(.form-renderer) {
    width: 100%;
  }

  & .ai-agent-message .ai-agent-message-content-box {
    box-sizing: border-box;
    background: #fff;
    border-radius: 10px;
    margin-top: 0;
    white-space: pre-line;
    min-height: 40px;
    padding: 10px 15px;
    font-size: 13px;
    word-break: break-word;
    display: flex;
    flex-direction: column;

    & * {
      font-size: 13px;
    }

    &.pending-message {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
    }

    &.markdown-message {
      white-space: normal;
      display: flex;
      flex-direction: column;
      gap: 12px;

      &:has(.form-renderer) {
        width: 100%;
      }

      & p {
        margin: 0;
      }

      & table {
        width: auto;
        overflow-x: auto;
        white-space: nowrap;
        border-spacing: 0 1px;
        background-color: #e3eaf8;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
      }

      & table thead {
        color: #333;
        font-weight: 500;
      }

      & table tbody {
        background-color: #f3f7ff;
      }

      & table th,
      & table td {
        padding: 4px 8px;
      }
    }

    &.plaintext-message {
      color: #3f495d;
    }

    &.image-message img {
      max-width: calc(100% - 60px);
      max-height: 450px;
    }
  }

  & .ai-agent-message .message-with-actions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    & .message-actions {
      display: flex;
      flex-flow: wrap;
      align-items: flex-start;
      gap: 8px;

      & .message-action {
        border: 1px solid #e3eaf8;
        border-radius: 16px;
        cursor: pointer;
      }

      & button:disabled {
        cursor: not-allowed;
      }

      & .message-action-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
      }

      & .message-action-help-text {
        color: #a6a6a6;
      }
    }
  }

  & .ai-agent-message .ai-agent-message-footer {
    width: 100%;

    & .message-actions {
      float: left;
    }

    & .message-source {
      float: right;
    }
  }

  & .ai-agent-conversation-example {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 8px;

    & .ai-agent-conversation-example-content {
      box-sizing: border-box;
      background: #ffffff80;
      border: 1px solid #e3eaf8;
      border-radius: 10px;
      margin-top: 0;
      white-space: pre-line;
      min-height: 44px;
      padding: 10px 15px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 4px;
    }
  }
}

.ai-agent-input-box-inner {
  position: relative;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  margin-bottom: 8px;

  & textarea {
    color: #333;

    &.allow-suggestion {
      color: #0000;
      caret-color: #000;
    }
  }

  & .ai-agent-input-box-mirror,
  & textarea {
    width: 100%;
    max-height: min(600px, 40vh);
    padding: 8px;
    box-sizing: border-box;
    background: none;
    border: none;
    outline: none;
    box-shadow: none;
    overflow-y: auto;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    resize: none;
    line-height: 1.2;
    letter-spacing: 0.1px;
    font-family: var(--font-family);
    font-size: 13px;
  }

  & .ai-agent-input-box-mirror {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    color: #333;
    overflow: visible;
  }
}


/* stylelint-disable-next-line selector-pseudo-element-no-unknown */
::highlight(ai-agent-ref-form) {
  color: rgb(166 97 253);
}
/* stylelint-disable-next-line selector-pseudo-element-no-unknown */
::highlight(ai-agent-ref-field) {
  color: rgb(255 169 64);
}

.ai-agent .ai-agent-message .ai-agent-message-content-box .ai-agent-message-content-box-footer {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;

  & .feedback-actions {
    display: flex;
    gap: 10px;
  }

  & .feedback-actions > .feedback-action-button {
    border: none;
    box-shadow: none;
    cursor: pointer;
    width: 24px;
    height: 24px;
    color: #7b8495;
    background-color: transparent;

    &:hover {
      border-radius: 2px;
      color: #3f495d;
      background-color: #f0f5ff;
    }

    &:active,
    &.feedback-action-current {
      color: #1890ff;
    }

    &.feedback-submitted {
      color: #d2d2d2;
      background-color: transparent;
      cursor: not-allowed;
    }
  }
}

.ai-agent-feedback-popover-panel {
  display: flex;
  flex-direction: column;
  padding: 10px 14px 14px;
  background-color: #fff;

  & .feedback-popover-header {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  & .feedback-popover-title {
    flex: 1;
    color: #05004e;
    font-size: 14px;
    font-weight: 500;
  }

  & .feedback-popover-actions {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    & .feedback-popover-submit {
      cursor: pointer;
    }
  }
}
