.mobile-layout {
  /* TODO, 是否可以改成 100% */
  min-height: 100vh;
  overflow: hidden;

  & .input-element {
    width: 100%;
  }

  & .date-picker-element {
    & .ant-picker {
      width: 100%;
    }
  }

  & .number-picker-element {
    & .ant-input-number {
      width: 100%;
    }
  }

  & .field-title {
    min-width: 0;
  }

  & .card-layout:not(.suppress-margin) {
    margin: 12px;
  }

  & .input-element.textarea-element {
    flex-direction: column;
    text-align: left;

    & textarea {
      /* TODO, 默认就给所有 mobile 的 input 去掉 border，不用在 json 里每个写 bordered: false  */
      padding-left: 0;
    }
  }

  & .input-element.upload-element {
    flex-direction: column;
    text-align: left;
  }

  & .list-element {
    gap: 12px;
  }

  /* redefine antD button size */

  & .ant-btn {
    border-radius: 48px;
  }

  & .ant-btn-lg {
    height: 48px;
  }

  /* cover antD steps style at mobile */

  & .ant-steps-dot .ant-steps-item-content,
  & .ant-steps-dot.ant-steps-small .ant-steps-item-content {
    width: initial;
    min-width: 80px;
  }

  & .ant-steps-dot .ant-steps-item-tail,
  & .ant-steps-dot.ant-steps-small .ant-steps-item-tail {
    margin-left: calc(50% - 2.5px);
  }

  & .ant-steps-dot .ant-steps-item-icon,
  & .ant-steps-dot.ant-steps-small .ant-steps-item-icon {
    margin-left: 50%;
    transform: translateX(-50%);
  }

  & .ant-steps-item:last-child {
    flex: 1;
  }

  & .ant-upload.ant-upload-select-picture-card {
    width: 78px;
    height: 78px;
  }

  & .ant-upload-list-picture-card-container {
    width: 78px;
    height: 78px;
  }
}

.label-layout-horizontal {
  & .mobile-layout {
    /* Mobile wants text to be right */

    & .readonly-text {
      justify-content: flex-end;
    }

    & .input-element.textarea-element {
      flex-direction: column;

      & .readonly-text {
        justify-content: flex-start;
      }

      & textarea {
        /* TODO, 默认就给所有 mobile 的 input 去掉 border，不用在 json 里每个写 bordered: false  */
        padding-left: 0;
      }
    }

    & .row-element {
      justify-content: flex-end;
    }


    & .ant-select-selector {
      & .ant-select-selection-overflow {
        justify-content: flex-end;
      }
    }

    & .ant-input:not(textarea),
    & .ant-select,
    & .ant-picker-input > input,
    & .ant-input-number-input-wrap > input {
      text-align: right;
    }

    & .input-element:not(.textarea-element):not(.upload-element) label ~ div .helper-text {
      text-align: right;
    }
  }
}
