.icp-history-view {
  display: flex;
  height: 100%;
}

.icp-history-view-sidebar {
  flex: none;
  display: flex;
  flex-direction: column;
  width: 300px;
  border-right: 1px solid var(--border-color);
}

.icp-history-view-list {
  overflow: auto;
}

.icp-history-view-item {
  margin: 0 4px;
  padding: 4px 8px;
  border-radius: 4px;
  white-space: nowrap;
  cursor: pointer;

  &.active {
    background: rgb(0 0 0 / 4%);
  }

  &:hover {
    background: rgb(0 0 0 / 6%);

    & button {
      display: inline-block;
      vertical-align: top;
    }
  }

  & button {
    min-width: 24px;
    height: 24px;
    border-radius: 4px;
    padding: 0;
    margin-left: 4px;
    display: none;
  }

  & > div:first-child {
    display: flex;
    justify-content: space-between;
    line-height: 24px;

    & > div:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  & > div:last-child {
    font-size: 12px;
    color: rgb(0 0 0 / 60%);
  }
}
