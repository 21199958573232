.table-number-cell {
  display: block;
  text-align: right;
}

.table-status-cell {
  display: flex;
  align-items: center;

  & i {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
  }
}

.table-enum-cell {
  display: flex;
  align-items: center;
  /* 设置 relative 和 z-index: 0 保证在 designer 里 setting 的时候背景的 z-index:-1 不塌陷到下层看不见 */
  position: relative;
  z-index: 0;

  & > div {
    --enum-color: var(--font-color);
    box-sizing: border-box;
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 24px;
    line-height: var(--line-height);
    overflow: hidden;

    &.rounded {
      border-radius: 12px;
    }
  }

  & .style1 {
    & .table-enum-cell-icon {
      color: var(--enum-color);
    }
  }

  & .style2 {
    &::before {
      content: '';
      flex: none;
      margin-right: 6px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: var(--enum-color);
    }
  }

  & .style3,
  & .style4,
  & .style5,
  & .style6,
  & .style7 {
    padding: 0 8px;
    border-radius: var(--border-radius);

    &::before {
      content: '';
      position: absolute;
      inset: 0;
      z-index: -1;
      background: var(--enum-color);
    }

    & .table-enum-cell-icon {
      margin-left: -2px;
    }
  }

  & .style3 {
    color: var(--enum-color);

    &::before {
      opacity: 0.15;
    }
  }

  & .style4 {
    color: var(--enum-color);
    outline: 1px solid var(--enum-color);
    outline-offset: -1px;

    &::before {
      opacity: 0.15;
    }
  }

  & .style5 {
    color: var(--enum-color);
    outline: 1px solid var(--enum-color);
    outline-offset: -1px;

    &::before {
      opacity: 0;
    }
  }

  & .style6 {
    color: white;
  }

  & .style7 {
    &::before {
      opacity: 0.15;
    }
  }
}

.icp-ag-table.small .table-enum-cell {
  & > div {
    height: 20px;
  }
}

.table-enum-cell-icon {
  margin-right: 6px;
}

.table-enum-cell-text {
  display: inline-block;
}

.table-action-cell {
  display: flex;
  gap: 6px;
  align-items: center;

  & > a {
    line-height: 1;
  }

  & button {
    flex-shrink: 0;
    height: 24px;
    font-weight: normal;
    border-radius: 6px;

    & svg {
      width: 14px;
      height: 14px;
    }
  }

  & .ant-btn-link {
    padding: 0;
  }

  & .form-element {
    &.input-element {
      margin: 0;
    }
  }
}

.table-upload-cell.upload-element {
  & > div {
    display: flex;
    align-items: center;
  }

  & .ant-upload-list-item {
    margin-top: 0;
  }
}

.table-progress-cell {
  --status-color: var(--primary-color);
  justify-content: flex-end;

  &.warning {
    --status-color: var(--warning-color);
  }

  &.success {
    --status-color: var(--success-color);
  }

  &.error {
    --status-color: var(--error-color);
  }

  & .progress-bar {
    flex: 1;
    border-radius: 4px;
    background: rgb(0 0 0 / 6%);
    overflow: hidden;

    & > div {
      height: 100%;
      border-radius: inherit;
      background-color: var(--status-color);
    }
  }

  & .progress-text {
    width: 44px;
    text-align: right;
  }

  &.style1 {
    & .progress-bar {
      min-width: 10px;
      height: 4px;
    }
  }

  &.style2 {
    & .progress-bar {
      min-width: 10px;
      height: 8px;
    }
  }

  &.style3 {
    & .progress-bar {
      height: 24px;
      margin-right: -48px;
    }

    & .progress-text {
      margin-right: 4px;
    }
  }

  &.style4 {
    & .progress-bar {
      flex: none;
      background: transparent;

      & circle:first-child {
        stroke: rgb(0 0 0 / 6%);
      }

      & circle:last-child {
        stroke: var(--status-color);
        transform: rotate(-90deg);
        transform-origin: 50% 50%;
      }
    }
  }
}

.icp-ag-table.small .table-progress-cell.style3 .progress-bar {
  height: 20px;
}

