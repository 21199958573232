.icp-popover {
  box-sizing: border-box;
  position: absolute;
  background: white;
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  border-radius: var(--border-radius);
  max-width: calc(100% - var(--screen-gap) * 2);
  max-height: calc(100% - var(--screen-gap) * 2);
  overflow: auto;

  &.icp-resizable {
    overflow: visible;
  }
}
