@keyframes icp-dialog-in-transition {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.icp-dialog-root {
  animation: icp-dialog-in-transition 0.25s ease;

  & > .icp-backdrop {
    background: rgb(0 0 0 / 45%);
  }
}

.icp-dialog {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  width: 600px;
  max-height: calc(100% - 64px);
  margin: 32px;
  background: white;
  border-radius: var(--border-radius);
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%), 0 9px 28px 8px rgb(0 0 0 / 5%);
}

.icp-dialog-title {
  flex: none;
  padding: 16px 24px;
  font-size: 16px;
  font-weight: 700;

  & ~ .icp-dialog-content {
    padding-top: 0;
  }
}

.icp-dialog-content {
  flex: auto;
  padding: 20px 24px;
  overflow: auto;
}

.icp-dialog-footer {
  flex: none;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 24px 16px;

  & > *:not(:first-child) {
    margin-left: 8px;
  }
}
