@keyframes icp-loading-rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.icp-loading {
  display: inline-block;
  vertical-align: middle;
  line-height: 0;
  transition: opacity 0.4s linear;
}

.icp-loading-centered {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.icp-loading-overlay {
  position: absolute;
  inset: 0;
  background-color: rgb(255 255 255 / 50%);
  z-index: 2;
}

.icp-transparent {
  opacity: 0;
}

.icp-loading-16 {
  animation: icp-loading-rotation 1s steps(8, start) infinite;
}

.icp-loading-20 {
  animation: icp-loading-rotation 1s steps(12, start) infinite;
}
