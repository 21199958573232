.todo-list-section {
  display: flex;
  flex-direction: column;
  gap: 10px;
  height: 100%;

  & .todo-list-section-title {
    font-size: 24px;
    font-weight: 700;
    margin-left: 24px;
  }

  & .todo-list-items-wrapper {
    background-color: #fff;
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    padding: 0 20px;
    flex: 1;
  }

  & .todo-list-empty-message {
    color: #9ea0aa;
    margin-top: 20px;
    text-align: center;
  }


  & .todo-list-item {
    padding: 10px 0;
    display: flex;
    flex-direction: row;
    gap: 8px;
    font-size: 12px;
    color: rgb(51 51 51);
    border-bottom: 1px solid var(--border-color);
    align-items: center;

    & .todo-list-icon {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      margin-right: 5px;
      background-color: #fa8c16;
    }

    & .todo-list-msg {
      flex: 1;
      overflow: hidden;
    }
  }
}

.todo-list-wrapper {
  height: 100%;
}

.designer-todo-list-wrapper {
  height: 300px;
  position: relative;

  & .designer-todo-list-annotation {
    position: absolute;
    bottom: 0;
    font-size: 12px;
    color: var(--readonly-text-color);
    display: flex;
    gap: 10px;
    padding: 0 0 12px 24px;
  }
}
