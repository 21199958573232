.icp-field-tree-node-title-wrapper {
  position: relative;
}

.icp-field-tree-node-title-count {
  position: absolute;
  top: 50%;
  left: calc(100% + 10px);
  font-size: 12px;
  pointer-events: none;

  & > * {
    --size: 16px;
    position: absolute;
    top: calc(-0.5 * var(--size));
    left: 0;
    width: var(--size);
    height: var(--size);
    display: flex;
    align-items: center;
    justify-content: center;
    color: lightgray;
  }
}
