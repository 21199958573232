.ai-agent-icon {
  --size: 100%;

  width: var(--size);
  height: var(--size);
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../assets/img/ai-agent-icon-1.png');

  &.alive {
    background-image: url('../assets/img/ai-agent-icon-1.gif');
  }

  &.error {
    background-image: url('../assets/img/ai-agent-icon-3.png');
  }

  &.error.alive {
    background-image: url('../assets/img/ai-agent-icon-3.gif');
  }

  &.thinking {
    background-image: url('../assets/img/ai-agent-icon-4.png');
  }

  &.thinking.alive {
    background-image: url('../assets/img/ai-agent-icon-4.gif');
  }
}

.ai-agent-icon-hover-wrapper:hover .ai-agent-icon:not(.error, .thinking) {
  /* 静图不作hover，历史消息中头像不用动 */
  /* background-image: url('../assets/img/ai-agent-icon-2.png'); */

  &.alive {
    background-image: url('../assets/img/ai-agent-icon-2.gif');
  }
}
