.icp-toolbar {
  flex: none;
  -webkit-font-smoothing: initial;

  & hr {
    display: inline-block;
    width: 1px;
    height: 16px;
    margin: 0;
    border: 0;
    background: var(--border-color);
  }

  & .input-element {
    width: auto;
  }

  & button {
    flex: none;
  }

  & .ant-btn-text {
    /* 保持 ui 统一，值显示成文字的 ant button 去掉 hover transition */
    transition: none;
    gap: 6px;
  }
}

.icp-toolbar-main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 8px;
  border-bottom: 1px solid #e8e8e8;

  & > div {
    flex: auto;
    min-height: 44px;
  }

  & button.ant-btn-icon-only {
    &:not(.ant-btn-dangerous):not(:disabled) {
      color: #5f6368;
    }
  }
}

.icp-toolbar-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 4px;

  & > .icp-search.icp-opened {
    width: 200px;
  }
}

.icp-table-menu {
  min-width: 140px;
  padding: 4px;

  &.dragging {
    cursor: pointer;

    & #icp-drag-ghost {
      background: rgb(0 0 0 / 6%);
    }
  }

  & > .icp-action-button {
    width: 100%;
    height: 28px;
    justify-content: flex-start;
  }

  & > .item {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 6px;
    height: 28px;
    padding: 2px 10px;
    border-radius: 4px;
    user-select: none;
    cursor: pointer;

    &.dragging:hover {
      background: transparent;
    }
  }
}

.icp-table-column-select {
  display: flex;
  flex-direction: column;
  max-height: 480px;
  min-width: 260px;

  & .icp-search {
    margin: 6px 6px 2px;
  }

  & .icp-table-menu {
    flex: auto;
    overflow: auto;
  }

  & .drag-indicator {
    opacity: 0.45;
  }
}

.icp-table-setting-hidden-wrapper {
  position: absolute;
  right: 0;
  top: 44px;
  height: calc(100% - 44px);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  overflow: hidden;
  z-index: var(--max-z-index);
}

.icp-table-setting {
  box-sizing: border-box;
  width: 320px;
  height: 100%;
  margin-right: -1px; /* -1px to avoid border repeat with ag-grid */
  padding: 5px;
  border: 1px solid #e8e8e8;
  border-bottom: 0;
  border-right: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background: white;
  font-size: var(--font-size);
  overflow: auto;
  transition: all ease 0.15s;
}

.icp-table-setting-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0;
  margin: 12px 12px 24px;
  font-size: 18px;
  font-weight: 600;

  & > button {
    width: 24px;
    min-width: 24px;
    height: 24px;
  }
}

.icp-table-setting-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 12px;
}

.icp-table-setting-column {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 20px 12px;
}

.icp-table-setting-actions {
  margin: 12px 0;

  & > div {
    display: flex;
    justify-content: flex-start;
    padding: 0 10px;
  }
}

.icp-table-save-view {
  & > hr {
    width: 100%;
    height: 1px;
    margin: 4px 0;
    border: 0;
    background: var(--border-color);
  }

  & .item {
    justify-content: space-between;

    & > button {
      width: 20px;
      min-width: 20px;
      height: 20px;
      border-radius: 4px;
      opacity: 0;
    }

    &:hover button {
      opacity: 1;
    }
  }
}

.icp-fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw !important;
  height: 100vh !important;
  z-index: var(--max-z-index);
  background: white;

  & .icp-toolbar {
    padding-left: 6px;
    padding-right: 6px;
  }
}

.icp-combined-view-tab {
  z-index: 3; /* cover .icp-loading */

  & svg {
    color: currentcolor;
  }

  &.icp-tab {
    height: 32px;
    border-radius: 6px;
    font-weight: 500;

    &.current::after {
      bottom: -7px;
    }

    &:not(.current) {
      color: rgb(51 51 51 / 70%);
    }
  }
}
